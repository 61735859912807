@import 'scss/variables';

.MultiSelect {
  width: 100%;
  color: $black;

  .clear-indicator {
    padding: 0.5rem;
    font-weight: 600;
    cursor: pointer;
  }
}
