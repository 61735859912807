@import 'scss/variables';

.btn {
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  border: none;
  margin: 2px;
  font-size: initial;
  cursor: pointer;

  &.btn-primary {
    background: $purple;
    color: $white;
  }
  &.btn-warning {
    background-color: $yellow3;
    border: 1px solid $yellow2;
    color: $white;
  }
}
