.NFTContractAttributes {
  border: 1px solid $gray;
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: $black;
  height: fit-content;

  .contract-attributes-item {
    margin: 0.5rem 0;
    font-size: medium;
    color: $white;
  }
  .total-value {
    color: $white;
  }
}
