.ConnectDisconnectButton {
  margin-right: 1.25rem;

  .account {
    max-width: 100px;
    margin-right: 0.5rem;
  }

  .btn-connect,
  .btn-disconnect {
    display: inline-block;
    margin: 0;
  }
}
