$black: #000;
$white: #fff;
// $turquoise: #3adcb0;
$purple: #8b00f9;
$green: #71faa2;
$green_dark: #71faa233;
$red: #fa7171;
$red_light: #d45137;
$red_dark: #730313;
$blue_dark: #011193;
$blue_light: #3c78a7;
$gray_light: #cacaca;
$gray: #575656;
$gray_dark: #282828;
$yellow: #f5cf35;

$gren_gradient: #349856;
$blue_gradient: #21635f;

$yellow1: #d7bd69;
$yellow2: #ffeeba;
$yellow3: #654c01;

$background-color-warning: $yellow1;
$border-color-warning: $yellow2;
$color-warning: $yellow3;
