@import 'scss/variables';

.AvaxNetworkWarning {
  padding: 1rem;
  margin: 0.5rem 0;
  text-align: center;

  color: $color-warning;
  background-color: $background-color-warning;
  border-color: $border-color-warning;
  border-radius: 0.3rem;
}
