@import 'scss/variables';

.AccountView {
  .account-info {
    margin-bottom: 1rem;
  }

  .tabs {
    .tab-titles {
      border: 1px solid $gray;
      border-radius: 0.5rem 0.5rem 0 0;
      border-bottom: none;

      .tab-title {
        position: relative;
        top: 1px;
        padding: 0.25rem;
        font-weight: normal !important;

        &.active {
          border-bottom: 3px solid $green;
          button {
            font-weight: bold;
          }
        }

        button {
          border: none;
          background-color: transparent;
          padding: 10px;
          color: $white;
        }
      }
    }

    .tab-contents {
      border: 1px solid $gray;
      padding: 20px 20px;
      border-radius: 0 0 0.5rem 0.5rem;
      margin-bottom: 1rem;
    }
  }

  .my-nfts {
    display: flex;
    // width: 1150px;
    margin: 0 auto;
    margin-bottom: 2.5rem;
    flex-direction: column;

    .nfts-list {
      margin-top: 1rem;
      width: 100%;
      order: 2;

      .ThreeDiceNFTList,
      .MechaNFTList {
        margin-top: 1rem;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(auto-fill, minmax(300px, 0.8fr));
        justify-content: center;

        .nft-container {
          padding: 1.5rem;
          border-radius: 0.5rem;
          border: 1px solid $green;
          overflow: auto;
          background: $gray_dark;
          &:hover {
            background: $green_dark;
          }
          h1 {
            margin-top: 0;
          }
        }
        .contract-image {
          // max-height: 250px;
          width: auto;
          margin: auto;
          display: flex;
          margin-bottom: 1rem;
        }
      }
    }
    .nfts-filters {
      order: 1;

      .filter-container {
        display: none;
        .icon-close-filter {
          display: flex;
          justify-content: flex-end;
        }
        &.is-open {
          display: block;
          position: fixed;
          flex: 1;
          width: 200px;
          height: 100%;
          background: $gray_dark;
          top: 0;
          right: 0;
          padding: 1rem;
        }
      }
    }
  }

  .Paginator {
    margin-bottom: 1rem;
    div {
      margin-top: 0.5rem;
    }
  }
}

@media (min-width: 992px) {
  .AccountView {
    .Paginator {
      display: flex;
      justify-content: center;
      div {
        margin-top: 0;
      }
    }
    .my-nfts {
      flex-direction: row;

      .nfts-list {
        order: 1;

        .ThreeDiceNFTList,
        .MechaNFTList {
          justify-content: flex-start;
        }
      }

      .nfts-filters {
        overflow: visible;
        padding: 1rem;
        order: 2;

        .icon-filter {
          display: none !important;
        }
        .filter-container {
          position: relative !important;
          display: block !important;
          background: transparent !important;
          flex: 1;
          min-width: 200px;
          max-width: 200px;
        }
      }
    }
  }
}
