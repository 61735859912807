@import 'scss/variables';

.NFTDetailView {
  border: 1px solid $gray;
  padding: 2rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .go-back {
    font-size: large;
    display: flex;
    align-items: center;
    cursor: pointer;
    .icon-go-back {
      font-size: x-large;
      color: $purple;
      margin-right: 10px;
    }
  }

  .NFTDetail {
    flex-direction: column;
    align-items: start;
    width: 100%;
    .NFTTransferForm {
      max-width: 100%;
      margin-top: 1rem;
    }
    .contract-viewer {
      .contract-content {
        .contract-image {
          border: 1px solid $gray_dark;
        }
        .ThreeDiceContractAttributes,
        .MechaContractAttributes {
          display: grid;
          gap: 0 15px;
          p:nth-of-type(odd) {
            grid-column: 1;
          }
          p:nth-of-type(even) {
            grid-column: 2;
          }
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .NFTDetailView {
    .NFTDetail {
      .contract-viewer {
        .contract-content {
          display: flex;
          .contract-image {
            width: 50%;
            margin-bottom: 1rem;
            margin-right: 1rem;
          }
          .ThreeDiceContractAttributes,
          .MechaContractAttributes {
            display: block;
          }
        }
      }
      .nav-link {
        color: $purple;
      }
    }
  }
}

@media (min-width: 992px) {
  .NFTDetailView {
    margin: 3rem;
    .NFTDetail {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      .NFTTransferForm {
        margin-top: 0;
        max-width: 40%;
      }
      .contract-viewer {
        display: flex;
        flex-direction: row-reverse;
        width: 60%;
        margin-right: 1rem;
      }
    }
  }
}
