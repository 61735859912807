@import 'scss/variables';

.NFTTransferForm {
  min-width: 40%;
  background: $gray_dark;
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  border-radius: 0.5rem;

  .form-group {
    flex-direction: column;
    label {
      margin-bottom: 7px;
    }

    .form-control {
      font-size: large;
      align-items: baseline;

      &.valid {
        border-color: $green;
      }

      &.invalid {
        border-color: $red;
      }
    }
  }

  .btn-transfer {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin: auto;
    margin-top: 7px;
  }
}
