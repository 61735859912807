@import 'scss/variables';

.account-info {
  // border-bottom: 1px solid gray;
  margin-bottom: 1rem;
  background: $black;

  input {
    font-size: inherit;
    margin-left: 0.5rem;
    width: 50%;
  }
}

.account-info,
.no-account {
  margin-left: 1rem;
}
