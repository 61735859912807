@import './variables';
@import './buttons';
@import './components/components';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $black;
  color: $gray_light;
}

h1,
h2 {
  color: $green;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.w-100 {
  width: 100% !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row {
  flex-direction: row !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-self-center {
  justify-self: center !important;
}

.text-align-center {
  text-align: center !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-left {
  text-align: left !important;
}

.align-items-center {
  align-items: center !important;
}

.float-right {
  float: right;
}

.clickable {
  cursor: pointer;
}

.text-bold {
  font-weight: bold;
}

.icon-favorite {
  font-size: x-large;
  color: $red_light;
}

@for $i from 1 through 5 {
  .ml-#{$i},
  .mx-#{$i},
  .m-#{$i} {
    margin-left: calc(#{$i} * 0.25rem);
  }

  .mr-#{$i},
  .mx-#{$i},
  .m-#{$i} {
    margin-right: calc(#{$i} * 0.25rem);
  }

  .mt-#{$i},
  .my-#{$i},
  .m-#{$i} {
    margin-top: calc(#{$i} * 0.25rem);
  }

  .mb-#{$i},
  .my-#{$i},
  .m-#{$i} {
    margin-bottom: calc(#{$i} * 0.25rem);
  }

  // Padding ###########

  .pl-#{$i},
  .px-#{$i},
  .p-#{$i} {
    padding-left: calc(#{$i} * 0.25rem);
  }

  .pr-#{$i},
  .px-#{$i},
  .p-#{$i} {
    padding-right: calc(#{$i} * 0.25rem);
  }

  .pt-#{$i},
  .py-#{$i},
  .p-#{$i} {
    padding-top: calc(#{$i} * 0.25rem);
  }

  .pb-#{$i},
  .py-#{$i},
  .p-#{$i} {
    padding-bottom: calc(#{$i} * 0.25rem);
  }
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: $i;
  }
}
