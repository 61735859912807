@import 'scss/index';

.Tooltip {
  margin-right: 5px;
  &:hover .tooltip-text {
    visibility: visible;
  }
  .tooltip-icon {
    color: $purple;
  }
  .tooltip-text {
    visibility: hidden;
    // border: 1px solid $green;
    border-radius: 0.5rem;
    border-bottom: none;
    background-color: $gray_dark;
    padding: 0.5rem 0.75rem;
    display: flex;
    position: absolute;
    font-size: medium;
    max-width: 200px;
    line-height: 1.2;
  }
}
