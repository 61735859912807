@import 'scss/variables';

.Footer {
  margin-top: auto;
  background: linear-gradient(180deg, $gren_gradient 0%, $blue_gradient 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;

  .copyright {
    flex: 1;
  }

  .icons {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    a {
      color: $white;
      margin-right: 0.5rem;
      svg {
        font-size: xx-large;
      }
      &:hover {
        color: $purple;
      }
    }
  }
}
