.ThreeDiceNFTFilters {
  .filter-controller {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    // align-items: flex-end;

    &.checkbox-favorite {
      flex-direction: column;
      // align-items: flex-start;
      input {
        margin-bottom: 5px;
      }
    }

    label {
      // display: flex;
      &.label-range {
        flex-direction: column;
      }
    }

    .filter-clear {
      margin-left: 0.5rem;
      max-width: 30px;
      max-height: 30px;
      display: flex;
      justify-content: center;
    }
  }
}
