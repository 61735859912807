@import 'scss/variables';

.NotFoundView {
  text-align: center;

  h1 {
    font-size: 10rem;
    margin: 0;
  }
  h2 {
    font-size: 6rem;
    margin: 0;
  }
  a {
    color: $white;
    text-decoration: none;
    font-size: x-large;
    color: $purple;
    margin-right: 10px;
  }
}
