@import 'scss/variables';

.TopBar {
  background: linear-gradient(180deg, $gren_gradient 0%, $blue_gradient 100%);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  .logo {
    background-color: $black;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 100%;
    img {
      max-height: 58px;
    }
  }
  ul.navigation {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;

      a {
        padding: 1.25rem;
      }

      a,
      button {
        display: inline-block;
        color: $white;
        text-decoration: none;

        &.active {
          background: $black;
        }
      }
    }
  }

  .ConnectDisconnectButton {
    display: flex;
    justify-content: flex-end;
    flex: 1 1;
    width: 100%;
    .account {
      max-width: 100%;
      display: flex;
      align-items: center;

      a {
        padding: 1.25rem;
      }

      .btn-connect,
      .btn-disconnect {
        margin: -1.25rem;
        margin-left: 0.25rem;
      }

      &.right {
        float: right;
        margin-right: 1.25rem;
      }
    }
  }
}

@media (min-width: 576px) {
  .TopBar {
    flex-direction: row;
    align-items: center;
    .logo {
      width: fit-content;
      padding: 0 1.25rem;
    }
    ul.navigation {
      overflow: hidden;
      flex: 2 1;
    }
  }
}
