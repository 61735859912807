@import 'scss/variables';

.MechaNFTFilters {
  .filter-controller {
    margin-top: 0.5rem;
    display: flex;

    &.checkbox-favorite {
      flex-direction: column;
      input {
        margin-bottom: 5px;
      }
    }
    select {
      min-height: 25px;
    }

    .filter-clear {
      margin-left: 0.5rem;
    }
  }
}
