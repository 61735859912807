.App {
  max-width: 1500px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  .Footer {
    max-height: 58px;
    min-height: 58px;
  }

  .appBody {
    margin-top: 2rem;
  }
}
