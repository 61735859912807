.Paginator {
  text-align: center;
  button {
    padding: 5px 10px;
    margin: 0 2px;
  }
  span {
    margin: 5px;
  }
  input {
    width: 30px;
    height: 30px;
    font-size: large;
  }
}
